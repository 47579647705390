import React, { Component } from "react";
import queryString from "query-string";
import cookie from "react-cookies";
import axios from "axios";
import close from "../SywCheckoutImages/Close.svg";
import {CODE_200,LOADING,headers,NWEB,LWEB} from "../sywCheckoutConstantComponent/SywCheckoutConstant"
import { connect } from "react-redux";
import { fetchPrepareCheckout } from "../redux";
import { bindActionCreators } from "redux";
import * as asyContain from "../redux/sywCheckoutOrderConfirmation/sywCheckoutOrderConfirmationActions";
import SywCheckoutAnalytics from "../sywCheckoutOrderComponent/SywCheckoutAnalytics";
import AnalyticsWrapper from "../sywCheckoutOrderComponent/AnalyticsWrapper";
class SywCheckoutOrderComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sumitems: "",
      pagesData: "",
      isLoaded: false,
      error: null,
      errors: null,
      orderPlacedAPIData: "",
      orderConfirmationData: ""
    };
  }

  modelShow = e => {
    document.getElementById("backdrop").classList.add("show");
  }
  modelClose = e => {
    document.getElementById("backdrop").classList.remove("show");
  }
  componentDidMount(props) {
    if (this.props.location.state.orderPlacedData.responseCode === "200") {

	 headers.cartId= this.props.location.state.orderPlacedData.cartId;
	 headers.merchantClientId= cookie.load("merchant");
    headers.refid=cookie.load("refid")
      const requestOptions = {
        headers
      };

      this.props.fetchOrderConfirmation(requestOptions);
      this.setState({
        orderPlacedAPIData: this.props.location.state.orderPlacedData
      });
    }
  }

  render() {
    const {
      error,
      isLoaded,
      authflag,
      pagesData,
      orderConfirmationData
    } = this.state;
    if (this.props.pageData.orderConfirmationfailure) {
      return (<div>
            <div  class="flex center red mt-16">
              <form id="returnHome"/>
              <p><b>There is some problem now, please try to checkout after some time</b></p>
            </div>
            <a target="_self"  href={cookie.load("return_source")}>
              <div class="mt-32 flex center">
                <button class="btn-sm btn-primary">
                  Continue Shopping
                </button>
              </div>
            </a>
          </div>);
    } else if (this.props.pageData.orderConfirmationLoading) {
      return (
        <section class="container loading-card mt-32">
          <div class="loader" />
          <h5 class="mt-16">Loading...</h5>
        </section>
      );
    } else if (this.props.pageData.orderConfirmationDataError) {
      return (
        <div>
          {" "}
          {(() => {
            if (
              this.props.pageData.orderConfirmationDataError !== undefined &&
              this.props.pageData.orderConfirmationDataError !== null
            ) {
              return (
                <div>
                  {this.props.pageData.orderConfirmationDataError.map(error => {
                    return (
                      <div  class="flex center red mt-16">
                        <b>{error.message}</b>
                      </div>
                    );
                  }) }
                </div>
              );
            }
          })() }
        </div>
      );
    } else {
      return (
        <div>
          {/* <SywCheckoutAnalytics  cartId={this.props.location.state.orderPlacedData.cartId} response={this.props.pageData} tealiumEvent='purchase'/> */}
          <AnalyticsWrapper triggerType={"page"} event={"purchase"} data={{...this.props?.pageData?.orderConfirmationData}} cartId={this.props?.pageData?.orderConfirmationData.cartId}/>

          <form id="returnHome"/>
          <div className="container">
            <div class="mt-32 flex center">
              <h4 class="mb-2 medium">
                <strong>Order Confirmation</strong>
              </h4>
            </div>
            <div class="mb-2 mt-8 flex center">
              <h5 class="mb-24 medium ">
                Thank you for the order!
              </h5>
            </div>

            <div className="row">
              <div className="column col-7 col-s-7 mb-32">
                <div>
                  <h4 class="mb-2">
                    <strong>
                      Order #: {this.props.pageData.orderConfirmationData.omsId}
                    </strong>
                  </h4>
                </div>
                <div class="confirmation-card">
                  <div class="description">
                    <p class="mb-16">
                      Your electronic gift card is on its way to your email
                      inbox!
                    </p>
                    <p class="mb-16">
                      If you need further assistance please contact{" "}
                      <span class="baby-blue border-bottom">
                        <b ><a id="openModal" onClick={this.modelShow}> customer service</a></b>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="column col-5 col-s-5">
                <div>
                  <h4 class="mb-2">
                    <strong>Order Summary</strong>
                  </h4>
                </div>
                <div className="data-card">
                  <div class="description">
                    {this.props.pageData.orderConfirmationData.summary.items.map(item => {
                      return (
                        <div class="item mb-32">
                          <div class="item-image">
                            <h5>{item.itemName}</h5>
                          </div>
                          <div class="item-points">
                            <h5 class=" ml-8">
                              {this.props.pageData.orderConfirmationData
                                .summary.currency + item.salePrice}
                            </h5>
                          </div>
                        </div>
                      );
                    }) }
                    <div class="separator1" />
                    <div class="item mb-8">
                      <div class="item-image" />
                      <div class="item-points">
                        <h5 class="ml-8">
                         <b> Order Total: {" "}
                          {this.props.pageData.orderConfirmationData.summary
                            .currency +
                            this.props.pageData.orderConfirmationData.summary
                              .priceSubTotal}</b>
                        </h5>
                      </div>
                    </div>
                    {(() => {
                      if (
                        parseFloat(this.props.pageData.orderConfirmationData.summary.pointsDollarValue) !==
                        0
                      ) {
                        return (
                          <div class="item mb-8">
                            <div class="item-image" />
                            <div class="item-points">
                              <h5 class="ml-8 baby-blue">
                                Points Applied: {" "}
                                {this.props.pageData.orderConfirmationData.summary.redeemPoints} (
                                -{this.props.pageData.orderConfirmationData.summary.currency +
                                  this.props.pageData.orderConfirmationData.summary.pointsDollarValue})
                              </h5>
                            </div>
                          </div>
                        );
                      }
                    })() }
                    {this.props.pageData.orderConfirmationData.tenders.map(tender => {
                      if (
                        parseFloat(tender.amountPayable) !== 0 &&
                        "CC_DC" === tender.tenderType &&
                        tender.cardNumber !== undefined
                      ) {
                        return (
                          <div class="item ">
                            <div class="item-image" />
                            <div class="item-points">
                              <h5 class="ml-8 baby-blue">
                                Credit Card Applied: -{this.props.pageData.orderConfirmationData.summary
                                  .currency + tender.amountPayable}
                              </h5>
                            </div>
                          </div>
                        );
                      }
                    }) }
                    <div class="item mb-8 mt-32">
                      <div class="item-image" />
                      <div class="item-points">
                        <h5 class="ml-8">
                          <b>
                         Remaining Balance: {" "}
                            {this.props.pageData.orderConfirmationData.summary.currency +
                              this.props.pageData.orderConfirmationData.summary.tenderAmountDue}
                          </b>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <a target="_self"  href={cookie.load("return_source")}>
                  <div class="mt-16 confirmation-btn-card flex center">
                    <button class="btn-confirmation btn-primary">
                      Continue Shopping
                    </button>
                  </div>
                </a>
                <div id="backdrop" class="backdrop">
                  <div  class="flex center">
                    <div class="modal">
                      <div class="modal-header">
                        <button class="btn btn-text closeModal">
                          <img src={close} onClick={this.modelClose}alt="Close" />
                        </button>
                      </div>
                      <section class="modal-body">
                        <h4 class="mb-24 mt-8">Customer Support Service</h4>
                        <p class="mb-24">
                          <b>Email us at <span class="baby-blue"> sywsolutions@syw.com</span></b>
                        </p>

                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
//export default SywCheckoutOrderComplete;
export default connect(
  state => ({
    pageData: state.orderConfirmPageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(SywCheckoutOrderComplete);
