import React, { Component } from "react";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import cookie from "react-cookies";
import postscribe from "postscribe";
import RaiseExternalScript  from "./RaiseExternalScript";
import {CODE_200,LOADING,headers,NWEB,LWEB} from "../sywCheckoutConstantComponent/SywCheckoutConstant"
import SywCheckoutItem from "../sywCheckoutItemComponent/SywCheckoutItem";
import SywCheckoutPaymentDetails from "../sywCheckoutPaymentComponent/SywcheckoutPaymentDetails";
import SywCheckoutCard from "../sywCheckoutPaymentComponent/SywCheckoutCard";
import SywCheckoutDeliveryInfo from "../sywCheckoutDeliveryInfoComponent/SywCheckoutDeliveryInfo";
import SywCheckoutOrderSummary from "../sywCheckoutOrderSummaryComponent/SywCheckoutOrderSummary";
import SywCheckoutAnalytics from "./SywCheckoutAnalytics";

import { connect } from "react-redux";
import { fetchPrepareCheckout } from "../redux";
import { bindActionCreators } from "redux";
import * as asyContain from "../redux/sywCheckoutPlaceOrder/sywCheckoutPlaceOrderActions";
import SywCheckoutTnC from "../sywCheckoutTnCComponent/SywCheckoutTnC";
import SywCheckoutCitiModal from "../sywChckoutModalComponent/SywCheckoutCitiModal";
import AnalyticsWrapper from "./AnalyticsWrapper";
import { GTMAnalytics, analytics } from "./sywGTMAnalytics/Syw_GTM_Analytics";

class SywCheckoutOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authflag: false,
      error: null,
      errors: null,
      pointserrors: null,
      isLoaded: false,
      orderCompleteflag: "",
      summiteRespData: "",
      pagesData: "",
      order_summary_update: "",
      citiab:0,
      GTMHit: true,
    };
  }

  componentDidMount() {
    if (window.opener && !window.opener.closed) {
      window.opener.location.href = window.location.href;
      window.close();
    }

    const queryString = require("query-string");
    const values = queryString.parse(this.props.location.search);
    const refid = values.refid;
    const sywr = values.sywr;
    const source=values.source;

    if(source!==undefined && source===NWEB)
      cookie.save('return_source', process.env.REACT_APP_SYW_CONTINUE_SHOPPING_URL_N, {domain:'.shopyourway.com', path:'/' });
	  else  
      cookie.save('return_source', process.env.REACT_APP_SYW_CONTINUE_SHOPPING_URL_L, { domain:'.shopyourway.com', path:'/' });
    
    const merchantClientId = values.merchant;	
		delete values.merchant;
	
    this.setState({citiab:values.citiab});
    delete values.refid;
    delete values.sywr;	
    values.itemDesc = values.ffmType + " | " + values.valid;
    const items = [values];

    let requestBody = { items };

    if (cookie.load("cartId") !== undefined) {
      headers.cartId = cookie.load("cartId");
    }

    if (cookie.load("refid") !== undefined) {
      headers.refid = cookie.load("refid");
    }

    if (refid !== undefined) {
      if (cookie.load("refid") === refid) {

        delete requestBody.items;
      } else {
          //START---this is support to old flow using marchentid
            cookie.remove("merchant", {domain:'.shopyourway.com', path:'/'});
          //END---this is support to old flow using marchentid

        headers.cartId = "";
        headers.refid = refid;
      }
    }


if (merchantClientId != undefined) {
		cookie.save('merchant', merchantClientId, {maxAge:parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain:'.shopyourway.com', path:'/' });
	}
	headers.merchantClientId = cookie.load("merchant");
	

    // if (
    //   cookie.load("refid") !== undefined &&
    //   cookie.load("refid") === refid &&
    //   cookie.load("cartId") !== undefined
    // ) {
    //   headers.cartId = cookie.load("cartId");
    //   delete requestBody.items;
    // }
    const requestHeaders = {
      headers
    };
    
    //<script src="https://r0.shopyourway.com/w/profile" type="text/javascript"></script>
    // var rtocken = '<script type="text/javascript" src="https://r0.shopyourway.com/w/profile"> </script>';
    // postscribe("#root", rtocken);

    this.props.fetchPrepareCheckout(requestBody, requestHeaders, refid);
  }
componentDidUpdate(){
  if (this.props.pageData.isValid &&
    this.props.pageData.orderCompleteResponseCode !== CODE_200 && this.state.GTMHit && 
    process.env.REACT_APP_GTM_ANALYTICS==="true") {
    GTMAnalytics("page", "checkout",{...this.props?.pageData?.prepareResponse});
    this.setState({ GTMHit: false });
  }
}
  render() {
    const {
      error,
      isLoaded,
      authflag,
      pagesData,
      summiteRespData,
      orderCompleteflag,
      pointserrors
    } = this.state;

    if (this.props.pageData.orderCompleteResponseCode === CODE_200) {
      const { error, isLoaded, items } = this.state;
      if (error) {
        return <div>Error: {error.message}</div>;
      } else if (this.props.pageData.spcPageLoading) {
        return (

          <section class="container loading-card mt-32">
            <div class="loader" />
            <h5 class="mt-16">Generating output...</h5>
          </section>

        );
      } else {
        return (
          <Redirect
            to={{
              pathname: "/OrderPlaced",
              state: {
                orderPlacedData: this.props.pageData.orderPlacedResponse
              }
            }}
            />
        );
      }
    }

    if (this.props.pageData.spcPageError) {
      return (<div> 
        <AnalyticsWrapper  event={'checkout'} data={{...this.props?.pageData?.prepareResponse}}/>
            <div  class="flex center red mt-16">
              <form id="returnHome"/>
              <p><b>There is some problem now, please try to checkout after some time</b></p>
            </div>
            <a target="_self" href={cookie.load("return_source")}>
              <div class="mt-32 flex center">
                <button class="btn-sm btn-primary">
                  Continue Shopping
                </button>
              </div>
            </a>
          </div>);
    } else if (this.props.pageData.spcPageLoading) {
      return (
        <section class="container loading-card mt-32">
          <div class="loader" />
          <h5 class="mt-16">{LOADING}</h5>
        </section>
      );
    } else {
      if (
        this.props.pageData.isValid &&
        this.props.pageData.orderCompleteResponseCode !== CODE_200
      ) {
        return (
          <div>
            <RaiseExternalScript props={this.props.pageData?.prepareResponse?.addlAttrs?.merchantSessionKey}/>
           { process.env.REACT_APP_TEALIUM_ANALYTICS === "true" &&  <SywCheckoutAnalytics cart={{...this.props?.pageData?.prepareResponse}} tealiumEvent='checkout'/>}
            {/* <AnalyticsWrapper analyticsTool={"gtm"} triggerType={"page"} data={this.props.pageData} event={'checkout'}/> */}
            <div class="mb-32 mt-16 flex center medium">
              <h4 >
                <strong class="medium">Checkout</strong>
              </h4>
            </div>
            <div >
              {(() => {
                if (
                  this.props.pageData.placeOrderError !== undefined &&
                  this.props.pageData.placeOrderError !== null
                ) {
                  return (
                    <div class="flex center red mt-16">
                      <b>
                        {this.props.pageData.placeOrderError.responseMessage}
                      </b>

                    </div>
                  );
                }
              })() }
              <b class="flex center red mt-16 ">{this.state.errors}</b>
            </div>
            {(() => {
              if (pagesData.errors !== undefined) {
                return (
                  <div>
                    {pagesData.errors.map(prepareerr => {
                      return (
                        <div class="flex center red mt-16">
                          <b >{prepareerr.message}</b>

                        </div>
                      );
                    }) }
                  </div>
                );
              }
            })() }
            {(() => {
              if (
                this.props.pageData.applyPointsError !== undefined &&
                this.props.pageData.applyPointsError !== null
              ) {
                return (
                  <div>
                    <SywCheckoutAnalytics cart={this.props.pageData} tealiumEvent='checkout' isError='true'/>
                    {this.props.pageData.applyPointsError.map(pointserror => {
                      return (
                        <div class="flex center red mt-16">
                          <b >
                            {pointserror.message}
                          </b>

                        </div>
                      );
                    }) }
                  </div>
                );
              }
            })() }
            <div className="container">

              <div className="row" >
                <div className="column col-7 col-s-7">
                  <SywCheckoutDeliveryInfo />
                  <br />
                  <SywCheckoutPaymentDetails />
                  <br />
                  <SywCheckoutItem />
                  <br /></div>
                <div className="column col-5 col-s-5">
                  <SywCheckoutOrderSummary citiflag={this.state.citiab}/></div>
              </div>

              <div className="row" >
                <div className="column col-7 col-s-7">
                  <SywCheckoutTnC/>
                </div>
                <div className="column col-5 col-s-5">
                </div>
              </div>
            </div>
            <SywCheckoutCitiModal/>
          </div>
        );
      } else {
        return (
          <div>
            <div  class="flex center red mt-16">
              <form id="returnHome"/>
              <p><b>There is some problem now, please try to checkout after some time</b></p>
            </div>
            <a target="_self" href={cookie.load("return_source")}>
              <div class="mt-32 flex center">
                <button class="btn-sm btn-primary">
                  Continue Shopping
                </button>
              </div>
            </a>
          </div>
        );
      }
    }
  }
}
//export default SywCheckoutOrder;
export default connect(
  state => ({
    pageData: state.pageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(SywCheckoutOrder);
