import axios from 'axios'
import cookie from 'react-cookies'
import {
    FETCH_GIFTCARD_REQUEST,
    FETCH_GIFTCARD_SUCCESS,
    FETCH_GIFTCARD_FAILURE,
    FETCH_GIFFTCARD_SUCCESS_ERROR,
} from './sywCheckoutGiftCardTypes'


//Gift Card API Call Start
export const fetchGiftCard = (requestHeaders,id) => {
       return (dispatch) => {
        dispatch(fetchGiftCardRequest())
          axios.get(process.env.REACT_APP_GET_CARD+"?id="+id,
          //axios.get("https://payment.uat.telluride.intra.shopyourway.com/tell/api/card/v1/getcard?id="+id,

            requestHeaders).then(resp => {
                 if (resp.data.errors !== undefined && resp.data.errors.length !== 0) {
                    dispatch(fetchGiftCardSuccessError(resp.data))
                } else {
                dispatch(fetchGiftCardSuccess(resp.data))
                }
            })
            .catch(err => {
                dispatch(fetchGiftCardFailure(err.message))
            });
    }
}

export const fetchGiftCardRequest = () => {
    return {
        type: FETCH_GIFTCARD_REQUEST
    }
}

export const fetchGiftCardSuccess = apiData => {
    return {
        type: FETCH_GIFTCARD_SUCCESS,
        payload: apiData
    }
}
export const fetchGiftCardSuccessError = apiData => {
    return {
        type: FETCH_GIFFTCARD_SUCCESS_ERROR,
        payload: apiData
    }
}

export const fetchGiftCardFailure = error => {
    return {
        type: FETCH_GIFTCARD_FAILURE,
        payload: error
    }
}

//Gift Card API Call END