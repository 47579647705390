import {
    FETCH_GIFTCARD_REQUEST,
    FETCH_GIFTCARD_SUCCESS,
    FETCH_GIFTCARD_FAILURE,
    FETCH_GIFFTCARD_SUCCESS_ERROR,
} from './sywCheckoutGiftCardTypes'

const initialState = {
    giftCardLoading: true,
    giftCardData: '',
    giftCardfailure: '',
    giftCardDataError: null,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
case FETCH_GIFTCARD_REQUEST:
return {
        ...state,
    giftCardLoading: true
}
    case FETCH_GIFTCARD_SUCCESS:
return {
    giftCardLoading: false,
    giftCardData: action.payload,
    giftCardfailure: '',
    giftCardDataError: null,
}
case FETCH_GIFFTCARD_SUCCESS_ERROR:
return {
    ...state,
    giftCardLoading: false,
    //orderConfirmationData: action.payload,
    giftCardfailure: '',
    giftCardDataError: action.payload.errors,
}
    case FETCH_GIFTCARD_FAILURE:
return {
    giftCardLoading: false,
    giftCardfailure: action.payload
}

    default: return state
  }
}

export default reducer