import {
    FETCH_ORDERCONFIRMATION_REQUEST,
    FETCH_ORDERCONFIRMATION_SUCCESS,
    FETCH_ORDERCONFIRMATION_FAILURE,
    FETCH_ORDERCONFIRMATION_SUCCESS_ERROR,
} from './sywCheckoutOrderConfirmationTypes'

const initialState = {
    orderConfirmationLoading: true,
    orderConfirmationData: '',
    orderConfirmationfailure: '',
    orderConfirmationDataError: null,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDERCONFIRMATION_REQUEST:
            return {
        ...state,
    orderConfirmationLoading: true
      }
    case FETCH_ORDERCONFIRMATION_SUCCESS:
return {
    orderConfirmationLoading: false,
    orderConfirmationData: action.payload,
    orderConfirmationfailure: '',
    orderConfirmationDataError: null,
}
case FETCH_ORDERCONFIRMATION_SUCCESS_ERROR:
return {
    ...state,
    orderConfirmationLoading: false,
    //orderConfirmationData: action.payload,
    orderConfirmationfailure: '',
    orderConfirmationDataError: action.payload.errors,
}
    case FETCH_ORDERCONFIRMATION_FAILURE:
return {
    orderConfirmationLoading: false,
    orderConfirmationfailure: action.payload
}

    default: return state
  }
}

export default reducer