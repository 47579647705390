import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import SywCheckoutHeader from './sywCheckoutHeaderComponent/SywCheckoutHeader'
import SywCheckoutOrder from './sywCheckoutOrderComponent/SywCheckoutOrder'
import SywCheckoutOrderComplete from './sywCheckoutOrderCompleteComponent/SywCheckoutOrderComplete'
import SywCitiRedirect from "./sywCheckoutPaymentComponent/SywCitiRedirect";
import './sywCheckoutStyle/SywCheckoutMain.css'
import test from './test2'
import SywCheckoutGiftCard from './sywCheckoutGiftCardComponent/SywCheckoutGiftCard'
import { Provider } from 'react-redux'
import store from './redux/store'
import SywSessionCitiRedirect from "./sywCheckoutPaymentComponent/SywSessionCitiRedirect";
// <Route exact path="/test" component={test} />
//            <Route exact path="/test2" component={SywCheckoutOrder} />
const routing = (
  <Provider store={store}>
    <Router>
      <div>
        <Switch>
          <Route exact path="/order" >
            <SywCheckoutHeader/>
            <Route exact path="/order" component={SywCheckoutOrder} />
          </Route>
          <Route exact path="/OrderPlaced" >
            <SywCheckoutHeader/>
            <Route exact path="/OrderPlaced" component={SywCheckoutOrderComplete} />
          </Route>
          <Route exact path="/citiredirection" >
            <SywCheckoutHeader/>
            <Route exact path="/citiredirection" component={SywCitiRedirect} />
          </Route>

          <Route exact path="/ss/citiredirection" >
            <SywCheckoutHeader/>
            <Route exact path="/ss/citiredirection" component={SywSessionCitiRedirect} />
          </Route>
            
          <Route exact path="/getcard" component={"true"===process.env.REACT_APP_GET_CARD_FLAG?SywCheckoutGiftCard:""}/>
          <Route exact path="/test" component={"true"===process.env.REACT_APP_GET_CARD_FLAG?test:""} />
        </Switch>
      </div>
    </Router>
  </Provider>
);
ReactDOM.render(routing, document.getElementById("root"));