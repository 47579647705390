import React, { Component } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { fetchPrepareCheckout } from "../redux";
import { bindActionCreators } from "redux";
import * as asyContain from "../redux/sywCheckoutGiftCard/sywCheckoutGiftCardActions";
import SywCheckoutAnalytics from "../sywCheckoutOrderComponent/SywCheckoutAnalytics";
import {headers} from "../sywCheckoutConstantComponent/SywCheckoutConstant"
class SywCheckoutGiftCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
 componentDidMount(props) {
    const queryString = require("query-string");
    var values = queryString.parse(this.props.location.search);
    var refid = values.refid;
    var id = values.id;

    headers.refid = refid;
    const requestHeaders = {
      headers
    };
this.props.fetchGiftCard(requestHeaders, id);

  }
  render() {
    if (this.props.pageData.giftCardfailure) {
      return (<div>
            <div  class="flex center red mt-16">
              <form id="returnHome"/>
              <p><b>There is some problem now, please try to checkout after some time</b></p>
            </div>
           </div>);
    } else if (this.props.pageData.giftCardLoading) {
      return (
        <section class="container loading-card mt-32">
          <div class="loader" />
          <h5 class="mt-16">Loading...</h5>
        </section>
      );
    } else if (this.props.pageData.giftCardDataError) {
      return (
        <div>
          {" "}
          {(() => {
            if (
              this.props.pageData.giftCardDataError !== undefined &&
              this.props.pageData.giftCardDataError !== null
            ) {
              return (
                <div>
                  {this.props.pageData.giftCardDataError.map(error => {
                    return (
                      <div  class="flex center red mt-16">
                        <b>{error.message}</b>
                      </div>
                    );
                  }) }
                </div>
              );
            }
          })() }
        </div>
      );
    } else {
      return (
        <div className="container">

              <div className="row mt-32" >
                <div className="column col-7 col-s-7">
                  <div className="data-card mb-16">
                <div class="item-card">
                  <img src={this.props.pageData.giftCardData.brandLogo} alt="" />
                  <div class="description">
                    <h5 class="mt-4">
                      <b>{this.props.pageData.giftCardData.brandName} Gift Card, value: $
                      {this.props.pageData.giftCardData.cardValue}</b>
                    </h5>
                    <p class="mt-2  x-medium"><b>Card Number:</b> {this.props.pageData.giftCardData.cardNumber}</p>
                    <p class=" x-medium"><b>Card Pin:</b> {this.props.pageData.giftCardData.cardPin}</p>
                  </div>
                </div>
                    <div className="mb-16"/>
              </div>

                </div>
                <div className="column col-5 col-s-5">
                </div>
              </div>
              </div>
    );
    }
  }
}
export default connect(
  state => ({
    pageData: state.giftCardPageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(SywCheckoutGiftCard);
