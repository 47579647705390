import React, { Component } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { connect } from "react-redux";
import { fetchPrepareCheckout } from "../redux";
import { bindActionCreators } from "redux";
import cookie from "react-cookies";
import * as asyContain from "../redux/sywCheckoutPlaceOrder/sywCheckoutPlaceOrderActions";
import SywCheckoutCard from "../sywCheckoutPaymentComponent/SywCheckoutCard";
import {CODE_200,LOADING,headers} from "../sywCheckoutConstantComponent/SywCheckoutConstant"
class SywcheckoutPaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      syw_points_in_dollar: "",
      prev_points_in_dollar: "",
    };
    this.pointDollarbtn = React.createRef();
  }

  handleShow = event => {
    this.setState({ show: true });
  };
  componentDidUpdate() {
    if (
      this.state.cctxt1 !== "" &&
      this.state.cctxt2 !== "" &&
      this.state.cctxt3 !== "" &&
      this.state.cctxt4 !== "" &&
      this.state.cctxt5 !== ""
    ) {
      if (this.state.validateCCFieledfilled !== true) {
        this.setState({ validateCCFieledfilled: true });
      }
    } else {
      if (this.state.validateCCFieledfilled !== false) {
        this.setState({ validateCCFieledfilled: false });
      }
    }
  }

  pointsChangeEvent = ({ target }) => {
      if (
      target.value <= -0.01 ||
      target.value >= 10000 ||
      (target.value.indexOf(".") > -1 && target.value.split(".")[1].length > 2)
    ) {
      target.value = this.state.syw_points_in_dollar;
      if (
          target.value.indexOf(".") > -1 &&
          target.value.split(".")[1].length >2
        )target.value = "0.00"
    } else {
      this.setState({ syw_points_in_dollar: target.value });
    }

    if (target.value !== undefined && target.value !== "") {
      document.getElementById("sywpointserror").classList.add("error-message-display-none");
      this.setState({ fieldErrorssywpoint: "" });
    } else {
      document.getElementById("sywpointserror").classList.add("error-message-display");
      this.setState({
        fieldErrorssywpoint: "Enter positive points 0 or above "
      });
    }
  };
  pointsHandler = event => {
    this.setState({ fieldErrorssywpoint: "" });
    if (
      this.state.syw_points_in_dollar !== undefined &&
      this.state.syw_points_in_dollar !== ""
    ) {
      headers.cartId = cookie.load("cartId");
	  headers.merchantClientId = cookie.load("merchant");
	  headers.refid=cookie.load("refid")
      const requestOptions = {
        headers
      };

      this.props.fetchApplyPoints(
        this.state.syw_points_in_dollar,
        requestOptions
      );
    } else {
      this.setState({
        fieldErrorssywpoint: "Enter positive points 0 or above "
      });
    }
  };


  render() {
    const { error, fieldErrors } = this.state;
    if (this.props.pageData.applyPointsFailure) {
      return <div>Error: {this.props.pageData.applyPointsFailure}</div>;
    } else if (this.props.pageData.spcPageLoading) {
      return (
        <section class="container loading-card mt-32">
        <div class="loader" />
        <h5 class="mt-16">Loading...</h5>
      </section>
      );
    } else {
      const popover = (
        <Popover id="popover-basic">
          <Popover.Content>
            Click to Apply Button to update the points
          </Popover.Content>
        </Popover>
      );
      return (
        <div>
          <div>
            <h4 class="mb-2">
              <strong>Payment Method</strong>
            </h4>
          </div>
          <div className="data-card">
            <div class="description mb-4">
              {(() => {
                if (
                  this.props.pageData.SYW_POINTS &&
                  "SYW_POINTS" === this.props.pageData.SYW_POINTS.tenderType
                ) {
                  return (
                    <div>
                      <h5>

                        <b className="mb-4 baby-blue">
                          {this.props.pageData.SYW_POINTS.tenderName}:
                        </b>
                      </h5>
                      <p className="mt-2">
                        You Have <b><span class="baby-blue">{this.props.pageData.SYW_POINTS.currentPointsBalance}</span></b> Shop Your Way Points
                      </p>
                      <p class="grey60">
                        <small>
                          Enter ($) dollar amount below
                        </small>
                      </p>
                      <div class="input-box">
                      <span class="prefix">$</span>
                      <input
                        required
                        
                        placeholder="Enter Amount"
                        type="Number"
                        defaultValue={
                          this.props.pageData.SYW_POINTS.redeemPointsInDollar
                        }
                        onBlur={e => {
                          if (e.target.value >= -1 || e.target.value <= 10000) {
                            e.target.value = e.target.value * 100 / 100;
                            if (
                              e.target.value.indexOf(".") > -1 &&
                              e.target.value.split(".")[1].length === 1
                            )e.target.value = e.target.value + "0";
                            if (
                              e.target.value.indexOf(".") === -1 &&
                              e.target.value.length >= 1
                            )e.target.value = e.target.value + ".00";
                            if (
                              e.target.value.indexOf(".") > -1 &&
                              e.target.value.split(".")[1].length >2
                            )e.target.value = "0.00"
                          }
                        }}
                        onChange={this.pointsChangeEvent}
                        onKeyPress={e => {
                          if (!/[0-9.]/.test(e.key)) {
                            e.preventDefault();
                          }
                          e.key === "Enter" && e.preventDefault();
                        }}
                        ref={e => (this.pointDollarbtn = e)}
                        step={0.01}
                        isInvalid={!!this.state.fieldErrorssywpoint}
                      />
                      </div>
                      <OverlayTrigger
                        show={
                          this.state.syw_points_in_dollar !== "" &&
                          parseFloat(this.state.syw_points_in_dollar) !==
                            parseFloat(
                              this.props.pageData.SYW_POINTS
                                .redeemPointsInDollar
                            )
                        }
                        placement="top"
                        overlay={popover}
                      >
                        <button
                          class="btn-outline btn-primary ml-4"
                          id="payButton"
                          onClick={this.pointsHandler}
                          disabled={
                            this.props.pageData.applyPointsLoading ||
                            !(
                              this.state.syw_points_in_dollar !== "" &&
                              parseFloat(this.state.syw_points_in_dollar) !==
                                parseFloat(
                                  this.props.pageData.SYW_POINTS
                                    .redeemPointsInDollar
                                )
                            )
                          }
                        >
                          Apply{" "}
                        </button>
                      </OverlayTrigger>
                             <p class="mb-2 mt-0" id="sywpointserror">
                                <small>{this.state.fieldErrorssywpoint}</small>
                              </p>
                      <p class="grey60">
                        <small>
                          {" "}
                          {this.props.pageData.SYW_POINTS.redeemPoints} Points |{" "}
                          {this.props.pageData.SYW_POINTS.pointsBalance} Points
                          Remaining
                        </small>
                      </p>
                    </div>
                  );
                }
              })()}
              <div />
            </div>
            <SywCheckoutCard />
          </div>
        </div>
      );
    }
  }
}
export default connect(
  state => ({
    pageData: state.pageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(SywcheckoutPaymentDetails);
