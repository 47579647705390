import React,{ useState, useEffect, useRef } from "react";
import { hideCitiBackdrop } from "../sywCheckoutStyle/SywCheckoutUtil"
import close from "../SywCheckoutImages/Close.svg";

const SywCheckoutCitiModal =() =>  {
    const [citiRdom, setCitiRdom] = useState("");
    useEffect(() => {}, []);
    return (
      <div id="citimodalbackdrop" class="citimodalbackdrop">
            <div  class="flex center">
              <div class="modal">
                <div class="modal-header">
                  <button class="btn btn-text closeModal" onClick={hideCitiBackdrop}>
                    <img src={close} alt="Close" />
                  </button>
                </div>
                <section class="modal-body">
                   <iframe src={process.env.REACT_APP_CITI_CARD_SEE_DETAILS_URL} 
                      width="100%" height="430px" frameBorder="0"></iframe>
                </section>
              </div>
            </div>
          </div>
    )
  
}
export default SywCheckoutCitiModal;