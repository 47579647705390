import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import queryString from "query-string";
import { fetchPrepareCheckout } from "../redux";
import { bindActionCreators } from "redux";
import * as asyContain from "../redux/sywCheckoutPlaceOrder/sywCheckoutPlaceOrderActions";
import SywCheckoutAnalytics from "../sywCheckoutOrderComponent/SywCheckoutAnalytics";
import { FFMSHIP,FFMEMAIL } from "../sywCheckoutConstantComponent/SywCheckoutConstant";
import AnalyticsWrapper from "../sywCheckoutOrderComponent/AnalyticsWrapper";
import { GTMAnalytics } from "../sywCheckoutOrderComponent/sywGTMAnalytics/Syw_GTM_Analytics";

class SywCitiRedirect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errors: null,
      citiurlflag:'',
      cartId: "",
    };
  }
  componentDidMount() {    
    const queryString = require("query-string");
    const values = queryString.parse(this.props.location.search);
    const citiRandom = values.citiRandom;
    const flag = values.flag;
    this.setState({citiurlflag:flag})
    if (cookie.load("citiRandom") === citiRandom) {
	// if ("1" === "1") {
      cookie.remove("citiRandom");
      let refid = "";
      const headers = {
        Accept: "Application/json",
        "Content-Type": "application/json",
        channel: "ONLINE",
        platform: "CHECKOUT",
       
        };
      
      if(flag==="0"){
        headers.platform="CHECKOUT";
		
        if (cookie.load("merchant") !== undefined) {
			headers.merchantClientId = cookie.load("merchant");
        }		

        if (cookie.load("cartId") !== undefined) {
			headers.cartId = cookie.load("cartId");
      
        }
        if (cookie.load("refid") !== undefined) {
          headers.refid = cookie.load("refid");
          refid = cookie.load("refid");
        }
      }

      if(flag==="2"){
        headers.platform="PYW";
		
        if (cookie.load("pywmerchant") !== undefined) {
			headers.merchantClientId = cookie.load("pywmerchant");
        }		

        if (cookie.load("pywcartId") !== undefined) {
			headers.cartId = cookie.load("pywcartId");
        }
        if (cookie.load("pywrefid") !== undefined) {
          headers.refid = cookie.load("pywrefid");
          refid = cookie.load("pywrefid");
        }
      }

      const requestHeaders = {
        headers
      };
      let requestBody = {};
      this.setState({ cartId: headers.cartId });
      this.props.fetchPrepareCheckout(requestBody, requestHeaders, refid);
    }
    cookie.remove("citiRandom");
  }
  componentDidUpdate() {

    if (
      this.props.pageData.isValid &&
      this.props.pageData.orderCompleteResponseCode !== "200" 
    ) {
      if(process.env.REACT_APP_GTM_ANALYTICS==="true") {
        GTMAnalytics("page" , "Linkclick" , {...this.props?.pageData?.prepareResponse}, this.state.cartId,  this.state.citiurlflag)
      }
      const timeout = setTimeout(() => {
        if(this.state.citiurlflag==="0")
        document.getElementById("applyBuyFormCart").action =process.env.REACT_APP_CITI_URL+(this.props.pageData.cmpCode!==undefined && this.props.pageData.cmpCode!==""?this.props.pageData.cmpCode:process.env.REACT_APP_CITI_URL_CMP);
          if(this.state.citiurlflag==="1")
          document.getElementById("applyBuyFormCart").action =process.env.REACT_APP_CITI_URL_new;
          if(this.state.citiurlflag==="2")
        document.getElementById("applyBuyFormCart").action =process.env.REACT_APP_CITI_URL+(this.props.pageData.cmpCode!==undefined && this.props.pageData.cmpCode!==""?this.props.pageData.cmpCode:process.env.REACT_APP_CITI_URL_CMP);
        document.getElementById("applyBuyFormCart").method = "POST";
        document.getElementById("applyBuyFormCart").submit();
      }, 0);
    }
  }
  render() {
    const { error } = this.state;

    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (this.props.pageData.spcPageLoading) {
      return (
        <section class="container loading-card mt-32">
        <div class="loader" />
        <h5 class="mt-16">Loading...</h5>
      </section>
      );
    } else if (
      this.props.pageData.isValid &&
      this.props.pageData.orderCompleteResponseCode !== "200"
    ) {
      return (
        <form name="applyBuyForm" id="applyBuyFormCart">
        {process.env.REACT_APP_TEALIUM_ANALYTICS === "true" && <SywCheckoutAnalytics  response={{...this.props?.pageData?.prepareResponse}} tealiumEvent='Linkclick' citiFlag={this.state.citiurlflag}/>}
        {/* <AnalyticsWrapper analyticsTool={"gtm"} triggerType={"page"} event={'Linkclick'} data={this.props.pageData} citiFlag={this.state.citiurlflag}/> */}
          <input
            type="hidden"
            name="Env"
            value={process.env.REACT_APP_CITI_ENV}
          />
 {(() => {
            if (
              this.state.citiurlflag ==="0") {
                return(
         <input
            type="hidden"
            name="returnpage"
            value={process.env.REACT_APP_CITI_RETURNPAGE}
          />
          );
            }
          })() }
          {(() => {
            if (
              this.state.citiurlflag ==="2") {
                return(
          <input
            type="hidden"
            name="returnpage"
            value={process.env.REACT_APP_CITI_RETURNPAGE_PYW}
          />
          );
            }
          })() }




          <input
            type="hidden"
            name="referenceNumber"
            value={cookie.load("cartId")}
          />
           {(() => {
            if (
              this.state.citiurlflag ==="0") {
                return(
          <input
            type="hidden"
            name="CUST_SESSION_ID"
            value={this.props.pageData.userSessionId}
          />
          );
            }
          })() }
          
          {(() => {
            if (
              this.state.citiurlflag ==="1") {
                return(
          <input
            type="hidden"
            name="CUST_SESSION_ID"
            value="12332112"
          />
          );
            }
          })() }
           {(() => {
            if (
              this.state.citiurlflag ==="2") {
                return(
          <input
            type="hidden"
            name="CUST_SESSION_ID"
            value={this.props.pageData.userSessionId}
          />
          );
            }
          })() }
          <input type="hidden" name="MERCHANT_PROCESS_TYPE" value="S" />
          <input
            type="hidden"
            name="SALE_AMT"
            value={parseFloat(this.props.pageData.summary.priceSubTotal)}
          />
          {this.props.pageData.ffm.map(ffm => {
            if (FFMEMAIL === ffm.type) {
              return (
                <div>
                  <input
                    type="hidden"
                    name="FIRST_NAME"
                    value={ffm.firstName}
                  />
                  <input type="hidden" name="LAST_NAME" value={ffm.lastName} />
                  <input type="hidden" name="EMAIL" value={ffm.emailId} />
                </div>
              );
            }else if(FFMSHIP === ffm.type){
              return(
                <div>                  
                  <input type="hidden" name="ADDRESS_LINE1" value={ffm.address.address1}  />
                  <input type="hidden" name="CITY" value={ffm.address.city} />
                  <input type="hidden" name="STATE" value={ffm.address.state}/>
                  <input type="hidden" name="ZIP" value={ffm.address.zipCode} />
                </div>
              );
            }
          })}
          <input type="hidden" name="SEARS_APT_SUITE" value="" />
        </form>
      );
    } else {
      return (
        <div className="c-r tac flx-10a m-0">
          <b>Access Denied</b>
        </div>
      );
    }
  }
}
export default connect(
  state => ({
    pageData: state.pageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(SywCitiRedirect);
