import { combineReducers } from 'redux'
import sywCheckoutPlaceOrderReducer from './sywCheckoutPlaceOrder/sywCheckoutPlaceOrderReducer'
import sywCheckoutOrderConfirmationReducer from './sywCheckoutOrderConfirmation/sywCheckoutOrderConfirmationReducer'
import sywCheckoutGiftCardReducer from './sywCheckoutGiftCard/sywCheckoutGiftCardReducer'

const rootReducer = combineReducers({
  pageData: sywCheckoutPlaceOrderReducer,
  orderConfirmPageData:sywCheckoutOrderConfirmationReducer,
  giftCardPageData:sywCheckoutGiftCardReducer
})
export default rootReducer
