import React, { Component } from "react";
import cookie from "react-cookies";

var childwin = null;
var intervalID = null;
export function handleShow(){return true}
export function openPopup(flag) {
  if (childwin == null || childwin.closed) {
    var popupWinWidth=520;
    var popupWinHeight=600;
    var winLeft = (window.screen.width - popupWinWidth) / 2;
    var winTop = (window.screen.height - popupWinHeight) / 4;
    var params =
      "left=" + winLeft +",top=" + winTop+100 +
      ",width="+popupWinWidth+",height="+popupWinHeight+",menubar=0,status=0,titlebar=0,scrollbars=1,resizable=0";
     document.body.style.cursor= "wait";
     var citiRdom = Date.now() +""+window.performance.timing.navigationStart * window.performance.now()
    +""+Math.floor(Date.now()+ Math.random() * Date.now());

    //var openUrl = window.location.protocol+"//"+window.location.host + "/citiredirection";
    cookie.save('citiRandom', citiRdom, { maxAge:parseInt(process.env.REACT_APP_CART_ID_COOKIES_AGE), domain:'.shopyourway.com', path:'/' });
    var openUrl = window.location.origin+ "/citiredirection?citiRandom="+citiRdom+"&flag="+flag;
    setInterval(parentDisable,10);
    childwin = window.open(openUrl, "SYWPay", params);
    //childwin.fadeOut();
  } else {
    // parentDisable();
    // childwin.focus();
  }
  //clearInterval(intervalID);
  intervalID = setInterval(reloadParentWin, 100);
  //setInterval(reloadParentWin, 100);
}

function reloadParentWin() {
  if (childwin == null || childwin.closed) {
   var winItervalID= setInterval(window.location.reload(), 0);
     clearInterval(winItervalID);
      clearInterval(intervalID);
  }
}

function parentDisable() {
  if (childwin != null && !childwin.closed) {
    childwin.focus();
  }
}
