import React, { useEffect } from "react";
function RaiseExternalScript(sessionId) {
    const url = process.env.REACT_APP_RAISE_SCRIPT_URL;

    useEffect(() => {
        let script = document.querySelector(`script[src="${url}"]`);

        const clientId = process.env.REACT_APP_RAISE_SCRIPT_CLIENT_ID;
        const sessionKey = sessionId.props  ? sessionId.props : '';
        const isSandbox = process.env.REACT_APP_RAISE_SCRIPT_IS_SANDBOX  === "true" ? true : false;
        if (!script) {
            script = document.createElement("script");
            script.type = "application/javascript";
            script.src = url;
            script.async = true;
            script.onload = function () {
                window._Raise.initialize({ clientId, sessionKey, isSandbox });
            };
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(script, s);
            document.body.appendChild(script);
        }
    }, [url]);

    return <></>
};

export default React.memo(RaiseExternalScript);