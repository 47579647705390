import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPrepareCheckout } from "../redux";
import { bindActionCreators } from "redux";
import * as asyContain from "../redux/sywCheckoutPlaceOrder/sywCheckoutPlaceOrderActions";

class SywCheckoutDeliveryInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
    };
  }

  render() {
    const { error, isLoaded} = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (this.props.pageData.spcPageLoading) {
      return (
        <section class="container loading-card mt-32">
        <div class="loader" />
        <h5 class="mt-16">Loading...</h5>
      </section>
      );
    } else {
      return (
        <div>
          <div>
            <h4 class="mb-2">
              <strong>Delivery Information</strong>
            </h4>
          </div>
          {this.props.pageData.ffm.map(ffm => {
            if ("EMAIL" === ffm.type) {
              return (
                <div className="data-card">
                  <div class="description">
                    <h5>
                      <b class="mb-4 capitalize">{ffm.firstName + " " + ffm.lastName}</b>
                    </h5>
                    <p class="mt-2 lowercase">{ffm.emailId}</p>
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    }
  }
}
//export default SywCheckoutDeliveryInfo;

export default connect(
  state => ({
    pageData: state.pageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(SywCheckoutDeliveryInfo);
