import React, { Component } from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { fetchPrepareCheckout } from "./redux";
import { bindActionCreators } from "redux";
import * as asyContain from "./redux/sywCheckoutGiftCard/sywCheckoutGiftCardActions";
import SywCheckoutAnalytics from "./sywCheckoutOrderComponent/SywCheckoutAnalytics";

//import Test1 from './Test1'
class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      show: false,
      env1:"",
      rtpage:"",
      rfNumber:"",
      CUSTID:"",
      MERTYPE:"",
      SAAMT:"",
      fname:"",
      lname:"",
      eml:"",
      cty:"",
      ADDLINE:"",
      stte:"",
      SRSSUITE:"",
      zp:"",
      METYPE:"",
    };
    //this.handel = this.handel.bind(this);
  }
   handle = ({ target }) => {
}
 componentDidMount(props) {

  }
 handleInputChange = ({ target }) => {
      if (target.name === "Env") {
       this.setState({ env1: target.value });
      }
      if (target.name === "returnpage") {
       this.setState({ rtpage: target.value });
      }
      if (target.name === "referenceNumber") {
       this.setState({ rfNumber: target.value });
      }
      if (target.name === "CUST_SESSION_ID") {
       this.setState({ CUSTID: target.value });
      }
      if (target.name === "MERCHANT_PROCESS_TYPE") {
       this.setState({ MERTYPE: target.value });
      }
      if (target.name === "SALE_AMT") {
       this.setState({ SAAMT: target.value });
      }
      if (target.name === "FIRST_NAME") {
       this.setState({ fname: target.value });
      }
      if (target.name === "LAST_NAME") {
       this.setState({ lname: target.value });
      }
      if (target.name === "EMAIL") {
       this.setState({ eml: target.value });
      }
      if (target.name === "CITY") {
       this.setState({ cty: target.value });
      }
      if (target.name === "ADDRESS_LINE1") {
       this.setState({ ADDLINE: target.value });
      }
      if (target.name === "SEARS_APT_SUITE") {
       this.setState({ SRSSUITE: target.value });
      }
      if (target.name === "STATE") {
       this.setState({ stte: target.value });
      }
      if (target.name === "ZIP") {
       this.setState({ zp: target.value });
      }
}
  citisubmit = ({ target }) => {

    // alert(
    // this.state.env1+" 1 "+
    // this.state.rtpage+" 2 "+
    // this.state.rfNumber+" 3 "+
    // this.state.CUSTID+" 4 "+
    // this.state.MERTYPE+" 5 "+
    // this.state.SAAMT+" 6 "+
    // this.state.fname+" 7 "+
    // this.state.lname+" 8 "+
    // this.state.eml+" 9 "+
    // this.state.cty+" 10 "+
    // this.state.ADDLINE+" 11 "+
    // this.state.SRSSUITE+" 12 "+
    // this.state.stte+" 13 "+
    // this.state.zp+" 14 "
    // )
      const timeout = setTimeout(() => {
        document.getElementById("applyBuyFormCart").action =
          process.env.REACT_APP_CITI_URL;
        document.getElementById("applyBuyFormCart").method = "POST";
        document.getElementById("applyBuyFormCart").submit();
      }, 0);

  }
  render() {

      return (
        <div className="container">

              <div className="row mt-32" >
                <div className="column col-7 col-s-7">
                  <div className="item-card no-select mt-16 mb-16">
                  <from>
                <label class="selection-card mt-16">
                <input required class="input-text" name="Env" onChange={this.handleInputChange} type="text" />
                <span class="label-span">ENV {" ("+process.env.REACT_APP_CITI_ENV+" )"}</span>
              </label>
                <label class="selection-card">
                  <input required class="input-text" name="returnpage" onChange={this.handleInputChange} type="text" />
                  <span class="label-span">Return page {" ( "+process.env.REACT_APP_CITI_RETURNPAGE+" )"}</span>
                </label>
                <label class="selection-card">
                  <input required class ="input-text" onChange={this.handleInputChange} name="referenceNumber" type="text" />
                  <span class="label-span">Reference Number</span>
                </label>

                <label class="selection-card mt-16">
                <input required class="input-text" onChange={this.handleInputChange} name="CUST_SESSION_ID" type="text" />
                <span class="label-span">CUST SESSION ID</span>
              </label>
              <label class="selection-card mt-16">
                <input required class="input-text" onChange={this.handleInputChange} name="MERCHANT_PROCESS_TYPE" type="text" />
                <span class="label-span">MERCHANT PROCESS TYPE ( S )</span>
              </label>
                <label class="selection-card">
                  <input required class="input-text " onChange={this.handleInputChange} name="SALE_AMT" type="text" />
                  <span class="label-span">SALE AMT</span>
                </label>
                <label class="selection-card">
                  <input required class ="input-text" onChange={this.handleInputChange} name="FIRST_NAME" type="text" />
                  <span class="label-span">FIRST NAME</span>
                </label>

                <label class="selection-card mt-16">
                <input required class="input-text" onChange={this.handleInputChange} name="LAST_NAME" type="text" />
                <span class="label-span">LAST NAME</span>
              </label>
                <label class="selection-card">
                  <input required class="input-text " onChange={this.handleInputChange} name="EMAIL" type="text" />
                  <span class="label-span">EMAIL</span>
                </label>
                <label class="selection-card">
                  <input required class ="input-text" onChange={this.handleInputChange} name="CITY" type="text" />
                  <span class="label-span">CITY</span>
                </label>

                <label class="selection-card mt-16">
                <input required class="input-text"onChange={this.handleInputChange} name="ADDRESS_LINE1" type="text" />
                <span class="label-span">ADDRESS LINE1</span>
              </label>
                <label class="selection-card">
                  <input required class="input-text " onChange={this.handleInputChange} name="SEARS_APT_SUITE" type="text" />
                  <span class="label-span">SEARS APT SUITE</span>
                </label>
                <label class="selection-card">
                  <input required class ="input-text"onChange={this.handleInputChange} name="STATE" type="text" />
                  <span class="label-span">STATE</span>
                </label>
              <label class="selection-card">
                  <input required class ="input-text"onChange={this.handleInputChange} name="ZIP"  type="text" />
                  <span class="label-span">ZIP</span>
                </label>
              <button class="btn btn-primary" onClick={this.citisubmit} id="payButton">Apply & Use</button>
</from>
        <form name="applyBuyForm" id="applyBuyFormCart">
          <input type="hidden"  name="Env" value={this.state.env1} />
          <input type="hidden"  name="returnpage"  value={this.state.rtpage}  />
          <input type="hidden"  name="referenceNumber"  value={this.state.rfNumber} />
          <input type="hidden"  name="CUST_SESSION_ID"  value={this.state.CUSTID} />
          <input type="hidden" name="MERCHANT_PROCESS_TYPE" value={this.state.MERTYPE}/>
          <input type="hidden" name="SALE_AMT" value={this.state.SAAMT}/>
          <input type="hidden" name="FIRST_NAME" value={this.state.fname}/>
          <input type="hidden" name="LAST_NAME" value={this.state.lname} />
          <input type="hidden" name="EMAIL" value={this.state.eml} />
          <input type="hidden" name="CITY" value={this.state.cty} />
          <input type="hidden" name="ADDRESS_LINE1" value={this.state.ADDLINE} />
          <input type="hidden" name="SEARS_APT_SUITE" value={this.state.SRSSUITE} />
          <input type="hidden" name="STATE" value={this.state.stte} />
          <input type="hidden" name="ZIP" value={this.state.zp} />
        </form>
                    <div className="mb-16"/>
              </div>

                </div>
                <div className="column col-5 col-s-5">
                </div>
              </div>
              </div>
    );
    }

}
//export default Test;
export default connect(
  state => ({
    pageData: state.giftCardPageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(Test);
