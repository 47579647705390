import React, { Component} from "react";
class SywCheckoutHeader extends Component {
  render() {
    return (
       <header class="main-header">
        <h2 >SHOP YOUR WAY<sup>®</sup></h2>
      </header>
    );
  }
}
export default SywCheckoutHeader;