import React, { Component } from "react";
import masterCard from "../SywCheckoutImages/mastercard.png";
import close from "../SywCheckoutImages/Close.svg";
import cookie from "react-cookies";
import Payment from "payment";
import {TRUE,FALSE} from "../sywCheckoutConstantComponent/SywCheckoutConstant"
import {openPopup,
  handleShow} from "../sywCheckoutPaymentComponent/SywCheckoutPopupUtil";
import { connect } from "react-redux";
import { fetchPrepareCheckout } from "../redux";
import { bindActionCreators } from "redux";
import * as asyContain from "../redux/sywCheckoutPlaceOrder/sywCheckoutPlaceOrderActions";
import { showCitiBackdrop } from "../sywCheckoutStyle/SywCheckoutUtil"
import * as rdd from 'react-device-detect';
class SywCheckoutOrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      update_flag: "true",
      flag: false,
      summarys: [],
      itemsList: [],
      show: false,
      buttonflag: true
    };
  }
  handleClose = event => {
    this.setState({ show: false });
  };

  handleShow = event => {
    this.setState({ show: true });
  };
  
  citiretailEvent = e => {
    if(!rdd.isBrowser){
        var citiRdo = Date.now() + "" + window.performance.timing.navigationStart * window.performance.now()
            + "" + Math.floor(Date.now() + Math.random() * Date.now())
        cookie.save('citiRandom', citiRdo, { path: '/' });
        window.location.href = window.location.origin+ "/citiredirection?flag="+e.target.value+"&citiRandom=" + citiRdo;
    }else{
    document.getElementById("citiApply").classList.add("show");
    openPopup(e.target.value);
    }
  };


submitOrder = event => {

  const headers = {
      Accept: "Application/json",
      "Content-Type": "application/json",
      channel: "ONLINE",
      platform:"CHECKOUT",
      refid:cookie.load("refid")
      };


    headers.cartId= cookie.load("cartId");
	headers.merchantClientId= cookie.load("merchant");
      const requestOptions = {
        headers
      };

    // alert("cc No : "+this.props.pageData.ccNumber+" Cvv : "+this.props.pageData.ccCvv+" Exp : "
    // +this.props.pageData.ccExpiry+" name : "+this.props.pageData.ccName+" zip : "+this.props.pageData.ccZipcode)
    if (parseFloat(this.props.pageData.summary.pointsDollarValue) === parseFloat(this.props.pageData.summary.priceSubTotal)) {
      this.props.fetchPlaceOrder(requestOptions);
    }else if (parseFloat(this.props.pageData.summary.pointsDollarValue) !== parseFloat(this.props.pageData.summary.priceSubTotal)
      &&this.props.pageData.saveCcId !== undefined&&this.props.pageData.saveCcId !== ''){

     if(this.props.pageData.saveCcId.selected!=="true"){   
      let ccNumber = "";
      let ccExp = "";
      let cchsape = "";
      let ccName = "";
      let ccType = "";
      let ccCvvreq = "";
      let postalCode = "";
      let resCountryCode="";
      let ffmZipCode="";
      {this.props.pageData.ffm.map(addresinfo => {
          if (addresinfo.address !== undefined && addresinfo.address.length!==0 ) {
           ffmZipCode= addresinfo.address.zipCode;            
          }});}
          if(this.props.pageData.CC_DC) {
       {this.props.pageData.CC_DC.savedCards.map(tender => {
          if (this.props.pageData.saveCcId.id === tender.paymentCardId) {
            ccNumber = tender.panLastFour;
            ccExp = tender.expiryDate;
            ccName = tender.nameOnCard;
            cchsape = tender.paymentSnapshotId;
            ccType = tender.cardType;
            ccCvvreq = tender.cvvRequired;
            postalCode=tender.address!==undefined?tender.address.postalCode:ffmZipCode;
            resCountryCode=tender.address!==undefined?tender.address.country:"";
          }});}
        }
        const requestBody = {
          payment: [
            {
              paymentType: "creditCard",
              creditCard: {
                cardNumber: ccNumber,
                paymentCardId: this.props.pageData.saveCcId.id,
                paymentSnapshotId: cchsape,
                creditCardType: ccType,
                expiryDate: this.props.pageData.saveCcId.expiryreq === 'Y'?this.props.pageData.saveCcExpiry.replace("/", "20"):ccExp,
                cvvNumber:this.props.pageData.saveCcId.cvvreq === 'Y'?this.props.pageData.saveCcCvv:"",
                cardHolderName: ccName,
                streetAddress: "USA",
                zipCode: postalCode,
                countryCode:"US", //resCountryCode,
                currency: "USD"
              }}]};
              this.props.fetchPlaceOrderAndUpdateCard(requestOptions, requestBody);
     }else{
        this.props.fetchPlaceOrder(requestOptions);
     }


    }else if ((this.props.pageData.CC_DC && this.props.pageData.CC_DC.cardNumber != undefined && this.props.pageData.CC_DC.cardNumber.length > 0) &&
      this.props.pageData.ccNumber === '' && this.props.pageData.ccCvv === '' && this.props.pageData.ccExpiry === '' &&
      this.props.pageData.ccName === '' && this.props.pageData.ccZipcode === '' && this.props.pageData.saveCcId === ''){
      this.props.fetchPlaceOrder(requestOptions);
    }else if ((this.props.pageData.ccNumber != '' && Payment.fns.validateCardNumber(this.props.pageData.ccNumber)) &&
      (this.props.pageData.ccCvv != '' && Payment.fns.validateCardCVC(this.props.pageData.ccCvv)) &&
      (this.props.pageData.ccExpiry != '' && Payment.fns.validateCardExpiry(this.props.pageData.ccExpiry)) &&
      this.props.pageData.ccName != '' && this.props.pageData.ccZipcode != ''
    ) {
      const requestBody = {
        payment: [
          {
            paymentType: "creditCard",
            creditCard: {
              cardNumber: this.props.pageData.ccNumber,
              creditCardType: Payment.fns.cardType(this.props.pageData.ccNumber),
              cardLogoName: Payment.fns.cardType(this.props.pageData.ccNumber),
              expiryDate: this.props.pageData.ccExpiry.replace("/", "20"),
              cvvNumber: this.props.pageData.ccCvv,
              cardHolderName: this.props.pageData.ccName,
              streetAddress: "USA",
              zipCode: this.props.pageData.ccZipcode,
              countryCode: "US",
              currency: "USD"
            }
          }
        ]
      };
      cookie.save("cardtype", Payment.fns.cardType(this.props.pageData.ccNumber), {
        path: "/"
      });
      this.props.fetchPlaceOrderAndUpdateCard(requestOptions, requestBody);
    } else {
      this.props.creditCardDetailsRequiredError();
    }
  };



  modelShow = e => {
    document.getElementById("backdrop").classList.add("show");
  }
  modelClose = e => {
    document.getElementById("backdrop").classList.remove("show");
  }

  render() {
    const { error, isLoaded, summarys, itemsList, show } = this.state;


    if (this.props.pageData.applyPointsFailure) {
      return <div>Error: {this.props.pageData.applyPointsFailure}</div>;
    } else if (this.props.pageData.spcPageLoading) {
      return (
        <section class="container loading-card mt-32">
          <div class="loader" />
          <h5 class="mt-16">Loading...</h5>
        </section>
      );
    } else {
      return (
        <div>
          {(() => {
            if(this.props.pageData.apply_and_buy){
              return (

                <div className=" coldisp2">
                  <div class="selection-card-over center">
                    <div class="selection-card no-select">
                      <img src={masterCard} class="mr-4 mt-4rem" alt="" />
                      <div class="description">
                        <p class="mt-16">
                          <b>Earn a <span class="semi-light-blue"> $75 </span>statement credit for every $500 
                                 spent, up to $225, with eligible purchases<sup>†</sup>.</b>
                        </p>
                        <p>
                          <span class="semi-light-blue">
                            <a id="openModal" onClick={showCitiBackdrop}><b class="semi-light-blue"><sup>†</sup>See details and exclusions</b></a>
                          </span>{" "}
                        </p>
                        <p>
                          <div class="flex mt-4">
                            <button
                              class="btn-sm btn-primary"
                              id="payButton"
                              onClick={this.citiretailEvent}
                              value="0"
                              >
                              Apply & use now
                            </button>
                          </div>
                        </p>
                      </div>
                    </div>
                    
                  </div>
                  <br />
                  <br />
                </div>
              );}
          })() }
          <div>
            <h4 class="mb-2">
              <strong>Order Summary</strong>
            </h4>
          </div>
          <div className="data-card">
            <div class="description">
              {this.props.pageData.summary.items.map(it => {
               
                return (
                  <div class="item mb-32">
                    <div class="item-image">
                      <h5>{it.itemName}</h5>
                    </div>
                    <div class="item-points">
                      <h5 class=" ml-8">
                        {it.quantity +
                          " x " +
                          this.props.pageData.summary.currency +
                          it.salePrice}
                      </h5>
                    </div>
                  </div>
                );
              }) }
              <div class="separator1" />
              <div class="item mb-8">
                <div class="item-image" />
                <div class="item-points">
                  <h5 class="ml-8">
                    <b>
                      Order Total: {" "}
                    {this.props.pageData.summary.currency +
                      this.props.pageData.summary.priceSubTotal}
                      </b>
                  </h5>
                </div>
              </div>

              {(() => {
                if (
                  parseFloat(this.props.pageData.summary.pointsDollarValue) !==
                  0
                ) {
                  return (
                    <div class="item mb-8">
                      <div class="item-image" />
                      <div class="item-points">
                        <h5 class="ml-8 baby-blue">
                          Points Applied: {" "}
                          {this.props.pageData.summary.redeemPoints} (-{this
                            .props.pageData.summary.currency +
                            this.props.pageData.summary.pointsDollarValue})
                        </h5>
                      </div>
                    </div>
                  );
                }
              })() }

              {this.props.pageData.tenders.map(tender => {
                if (
                  parseFloat(this.props.pageData.summary.paymentDue) !== 0 &&
                  "CC_DC" === tender.tenderType &&
                  tender.cardNumber !== undefined
                ) {
                  let falgcardavailable = "Credit Card";

                  if (
                    "CC_DC" === tender.tenderType &&
                    tender.cardNumber !== undefined
                  ) {
                    falgcardavailable =
                      "Card ending " +
                      tender.cardNumber.substring(
                        tender.cardNumber.length - 4,
                        tender.cardNumber.length
                      );
                  }

                  return (
                    <div class="item ">
                      <div class="item-image" />
                      <div class="item-points">
                        <h5 class="ml-8 baby-blue">
                          Credit Card Applied: -{this.props.pageData.summary
                            .currency + this.props.pageData.summary.paymentDue}
                        </h5>
                      </div>
                    </div>
                  );
                }
              }) }

              <div class="item mb-8 mt-32">
                <div class="item-image" />
                <div class="item-points">
                  <h5 class="ml-8">
                    <b>
                      Remaining Balance: {" "}
                      {this.props.pageData.summary.currency +
                        this.props.pageData.summary.totalPayable}
                    </b>
                  </h5>
                </div>
              </div>
              
              
              
              
            </div>
          </div>

{(() => {
                if (
                  (parseFloat(this.props.pageData.summary.tenderAmountDue)) !== 0
                  && !this.props.pageData.CC_DC
                ) {
                  return (
                      <div class=" center red mt-32">
                          <b>Not enough points to complete the order</b>

                        </div>                 

               );
                }
              })() }

          <button
            class="btn btn-primary mt-4 mb-16"
            id="payButton"
            onClick={this.submitOrder}
            disabled={(this.props.pageData.summary.priceSubTotal <= 0 || 
              ((( !(this.props.pageData.ccNumber != '' && Payment.fns.validateCardNumber(this.props.pageData.ccNumber)) ||
                !(this.props.pageData.ccCvv != '' && Payment.fns.validateCardCVC(this.props.pageData.ccCvv,
                Payment.fns.cardType(this.props.pageData.ccNumber))) ||
                !(this.props.pageData.ccExpiry != '' && Payment.fns.validateCardExpiry(this.props.pageData.ccExpiry)) ||
                this.props.pageData.ccName === '' || this.props.pageData.ccZipcode === ''
              )
              && (!(this.props.pageData.saveCcId !== ''&&this.props.pageData.saveCcId.id !== '')
              || (this.props.pageData.saveCcId !== ''&&this.props.pageData.saveCcId.selected !== 'true'
              && parseFloat(this.props.pageData.summary.paymentDue) > 0 &&this.props.pageData.saveCcId.cvvreq === 'Y'
              &&!(this.props.pageData.saveCcCvv != '' && Payment.fns.validateCardCVC(this.props.pageData.saveCcCvv,
               "mastercard")))
               || (this.props.pageData.saveCcId !== ''&&this.props.pageData.saveCcId.selected !== 'true'
               && parseFloat(this.props.pageData.summary.paymentDue) > 0 &&this.props.pageData.saveCcId.expiryreq === 'Y'
              &&!(this.props.pageData.saveCcExpiry != '' && Payment.fns.validateCardExpiry(this.props.pageData.saveCcExpiry)))
              )
              )&& (parseFloat(this.props.pageData.summary.tenderAmountDue) > 0
               ||(this.props.pageData.saveCcId !== ''&& this.props.pageData.saveCcId.selected !== 'true'
               && parseFloat(this.props.pageData.summary.paymentDue) > 0)||
               (this.props.pageData.CC_DC && this.props.pageData.CC_DC.cardNumber === '' && this.props.pageData.saveCcId === '' 
               && parseFloat(this.props.pageData.summary.paymentDue) > 0 )
               )
              )
              )? (
                  true
                ) : (
                  false
                )
            }
            >
            Place Order
          </button>

          <p class="order-refund ">* Order is not refundable</p>
          {(() => {
              if(this.props.pageData.apply_and_buy){
              return (
                <div className=" coldisp">
                  <br />
                  <br />
                  <div class="selection-card-over center">
                    <div class="selection-card no-select">
                      <img src={masterCard} class="mr-4 mt-5" alt="" />
                      <div class="description">
                        <p class="mt-16 mb-16">
                          <b>Earn a <span class="semi-light-blue"> $75 </span>statement credit for every $500 
                                 spent, up to $225, with eligible purchases<sup>†</sup>.</b>
                        </p>
                        <p>
                          <span class="semi-light-blue">
                            <a id="openModal" onClick={showCitiBackdrop}><b class="semi-light-blue"><sup>†</sup>See details and exclusions</b></a>
                          </span>
                        </p>
                        <p>
                          <div class="flex mt-4">
                            <button
                              class="btn-sm btn-primary"
                              id="payButton"
                              onClick={this.citiretailEvent}
                              value="0"
                              >
                              Apply & use now
                            </button>
                          </div>
                        </p>
                      </div>
                    </div>
                    
                  </div>
                </div>
              );}
          })() }


          <div id="citiApply" class="backdrop">
            <div  class="flex center">
              <div class="modal">
                <section class="modal-body">
                  <p class="mb-24 flex center">
                    Apply & Use inprogress...
                  </p>
                </section>
              </div>
            </div>
          </div>

          
        </div>
      );
    }
  }
}
//export default SywCheckoutOrderSummary;

export default connect(
  state => ({
    pageData: state.pageData
  }),
  dispatch =>
    bindActionCreators(
      {
        ...asyContain
      },
      dispatch
    )
)(SywCheckoutOrderSummary);
