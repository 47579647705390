import React, { Component} from "react";
import { connect } from 'react-redux'
import { fetchPrepareCheckout } from '../redux'
import { bindActionCreators } from 'redux'
import * as asyContain from '../redux/sywCheckoutPlaceOrder/sywCheckoutPlaceOrderActions'

class SywCheckoutTnC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
        };
    }

    render() {
        const { error} = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (this.props.pageData.spcPageLoading) {
            return (
                <section class="container loading-card mt-32">
                    <div class="loader" />
                    <h5 class="mt-16">Loading...</h5>
                </section>);
        } else {
            return (
                <div className="mt-32 trm-condtion" >
                            <div>
                                <p ><b>REDEMPTION DETAILS AND GIFT CARD TERMS</b></p>
                            </div>

                            <div class="mt-8">
                               <p class="small"> <b >DELIVERY & REDEMPTION</b></p>
                                <ul class="pl-4">
                                    <li><p class="x-small">This eGift Card can be redeemed online.</p></li>
                                    <li><p class="x-small">This printable voucher can be redeemed on location.</p></li>
                                    <li><p class="x-small">Most eGift Cards and printable vouchers are delivered to your email instantly. But please allow up to 24 hours.</p></li>
                                </ul>
                            </div>
                            <div>
                                <p class="small"><b>CARD DETAILS</b></p>
                                <ul class="pl-4">
                                    <li><p class="x-small">Free delivery.</p></li>
                                    <li><p class="x-small">Gift Cards can not be used to purchase other gift cards.</p></li>
                                </ul>
                            </div>
                            <div>
                               <p class="small"> <b>TERMS & CONDITIONS</b></p>
                              <p class="mt-8 small"><b > Shop Your Way<sup>®</sup></b></p>
                                <p class="x-small"> For questions about redeeming gift cards, Please visit Shop Your Way's Help Center at <br/><a  target="_blank" rel="noopener noreferrer" href="https://www.shopyourway.com/help">shopyourway.com/help</a></p>
                            </div>
                            {this.props.pageData.items.map((item) => {
                                return (
                                    <div>
                                        <p class="mt-8 small">   <b>{item.itemName}</b> </p>
                                         <p class="x-small">  {item.legalTerms} </p>
                                        <br/>
                                    </div>
                                );
                            }) }

                </div>
            )
        }
    }

}
//export default SywCheckoutDeliveryInfo;

export default connect(state => ({
    pageData: state.pageData
}),
    dispatch => bindActionCreators({
		...asyContain,
	}, dispatch)
)(SywCheckoutTnC)